@import "mixin";

.itn-header {
  background-color: #55a1d4;
  width: 100%;

  .itn-header-content {
    max-width: 1024px;
    margin: auto;
    padding: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .itn-header-logo {
      width: 7rem;
    }

    .itn-header-text-section {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .itn-header-account-section {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .itn-header-account,
        .itn-header-account-logout {
          font-size: 0.9rem;
          color: white;

          span {
            font-weight: 800;
          }
        }

        .itn-header-account-logout {
          text-decoration: underline;
          cursor: pointer;

          &:hover {
            color: #1d5377;
          }
        }
      }

      .itn-cms-link {
        display: inline-block;
        align-self: flex-end;
        text-decoration: none;
        color: #fff;
        background-color: #2b74a5;
        text-align: center;
        font-size: 0.82rem;
        padding: 0.6em 1.2em;
        border-radius: 5px;
        transition: all ease 200ms;
        width: 12rem;

        &:hover {
          background-color: #1d5377;
          cursor: pointer;
        }
      }

      .hidden {
        display: none;
      }

      .itn-header-text {
        color: #fff;
        font-weight: 500;
        font-style: italic;
        font-size: 0.8rem;
        line-height: 1.3em;
      }
    }
  }
}

@include desktop {
  .itn-header {
    .itn-header-content {
      width: 85%;

      .itn-header-logo {
        width: 6rem;
      }

      .itn-header-text-section {
        .itn-header-text {
          display: none;
        }
      }
    }
  }
}
