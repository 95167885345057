@import "mixin";
@import "animation";

.itn-scroll-top {
  display: none;
  font-size: 1.5rem;
  color: #fff;
  width: 4rem;
  height: 4rem;
  background-color: #55a1d4;
  border-radius: 50%;
  position: fixed;
  bottom: 3rem;
  right: 2rem;
  box-shadow: 0 1px 12px rgb(0 0 0 / 8%);
  cursor: pointer;
  transition: all 200ms ease;

  &:hover {
    background-color: #5180ae;
  }
}

.itn-scroll-top-show {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 500ms;
}

@include mobile {
  .itn-scroll-top {
    font-size: 1.5rem;
    width: 3.5rem;
    height: 3.5rem;
    right: 1.3rem;
    bottom: 2.5rem;
  }
}
