@import "reset";
@import "header";
@import "signin";
@import "signinform";
@import "main";
@import "menu";
@import "scrolltop";
@import "footer";

html {
  font-family: "Open Sans", sans-serif;
  color: #2f2f2f;
  background-color: #f9f9f9;
  scroll-behavior: smooth;
}
