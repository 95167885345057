@import "mixin";

.itn-footer {
  background-color: #b0b1b3;
  width: 100%;

  .itn-footer-content {
    max-width: 1024px;
    margin: auto;
    padding: 3rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .itn-footer-text {
      color: #fff;
      font-weight: 500;
      font-style: italic;
      font-size: 0.8rem;
      width: 30rem;
      line-height: 1.3em;
    }

    .itn-footer-logo {
      width: 10rem;
    }
  }
}

.itn-footer::after {
  position: fixed;
  background-color: #b0b1b3;
  height: 100%;
  width: 100%;
  content: "";
}

@include desktop {
  .itn-footer {
    .itn-footer-content {
      width: 85%;

      .itn-footer-text {
        width: 18rem;
      }

      .itn-footer-logo {
        width: 8rem;
      }
    }
  }
}

@include mobile {
  .itn-footer {
    .itn-footer-content {
      .itn-footer-text {
        width: 10rem;
      }
    }
  }
}
