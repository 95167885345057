.itn-signin-content-header {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .itn-signin-copy {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.2em;
  }

  .itn-signin-subtitle {
    font-size: 1.3rem;
    font-weight: 600;
  }

  .itn-signin-error {
    font-size: 13px;
    color: #eb4848;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: -8px;
    font-weight: 600;
  }
}

.itn-signin-form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .itn-signin-field {
    font-size: 15px;
    padding: 12px 15px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #a8a8a8;
    }
  }

  .itn-signin-password-field {
    width: 100%;
    display: flex;
    position: relative;

    .itn-signin-field {
      width: 100%;
      font-size: 15px;
      padding: 12px 15px;
      border-radius: 5px;
      border: none;
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #a8a8a8;
      }
    }

    .itn-signin-password-icon {
      opacity: 0.5;
      cursor: pointer;
      width: 18px;
      height: auto;
      object-fit: contain;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      transition: all ease 200ms;

      &:hover {
        opacity: 1;
      }
    }
  }

  .itn-signin-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #55a1d4;
    border-radius: 5px;
    color: #55a1d4;
    height: 44px;
    font-weight: 500;
    font-size: 15px;
    transition: all ease 200ms;

    &:hover {
      background-color: #5180ae;
      border-color: #5180ae;
      color: #fff;
    }

    &:disabled {
      background-color: #a8a8a8;
      border-color: #a8a8a8;
      color: white;
      cursor: not-allowed;
    }

    .itn-signin-animation {
      margin: 0;
      width: 35px;
      height: 35px;
    }
  }

  .itn-signin-password-policies {
    text-align: left;
    list-style: url("../../public/signin-icons/cross-icon.png") inside;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .itn-signin-password-policy {
      font-size: 12px;
      color: #a8a8a8;
    }

    .itn-signin-password-policy-complete {
      opacity: 1;
      color: green;
      list-style: url("../../public/signin-icons/check-icon.png") inside;
    }
  }
}

.itn-blackscreen {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}
.itn-password-confirm-window {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  background-color: white;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.08);
  padding: 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .itn-password-confirm-animation {
    margin: 0;
    width: 60px;
    height: 35px;
  }

  .itn-password-confirm-window-title {
    margin-top: -10px;
    font-size: 1.3rem;
    font-weight: 600;
  }

  .itn-password-confirm-window-text {
    font-size: 0.9rem;
    list-style: 1.2em;
  }

  .itn-password-confirm-window-button {
    cursor: pointer;
    border: none;
    background-color: #55a1d4;
    border-radius: 5px;
    color: #fff;
    padding: 12px;
    font-weight: 600;
    font-size: 15px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    transition: all ease 200ms;

    &:hover {
      background-color: #5180ae;
    }
  }
}
