@mixin desktop {
  @media (max-width: 1068px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 800px) {
    @content;
  }
}
