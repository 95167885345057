.itn-signin-page {
  width: 100vw;
  height: 100vh;
  background-color: #f7f7f8;
  color: #2f2f2f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .itn-signin-window {
    width: 400px;
    background-color: #fff;
    padding: 30px;
    padding-bottom: 45px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    box-shadow: 0 1px 12px rgba(0, 0, 0, 0.08);
    text-align: center;
    margin-top: -30px;

    .itn-signin-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .itn-signin-logo {
        width: 5rem;
        align-self: flex-start;
      }

      .itn-signin-title {
        background-color: #b9b9b9;
        color: #fff;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 15px;
      }
    }

    .itn-signin-animation {
      margin: 0;
      width: 35px;
      height: 35px;
    }

    .itn-signin-buttons {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      .itn-signin-with-itn {
        width: 80%;
        cursor: pointer;
        background-color: #55a1d4;
        border-radius: 5px;
        color: #fff;
        height: 44px;
        font-weight: 600;
        font-size: 15px;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 7px;
        transition: all ease 200ms;

        &:hover {
          background-color: #5180ae;
        }

        .itn-signin-with-itn-icon {
          width: 14px;
        }
      }
    }

    .itn-signin-divider {
      position: relative;
      width: 85%;
      height: 1px;
      background-color: #a8a8a8;

      .itn-signin-divider-text {
        font-size: 14px;

        position: absolute;
        width: 2rem;
        background-color: #fff;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        color: #a8a8a8;
      }
    }

    .itn-signin-content {
      width: 85%;
      display: flex;
      flex-direction: column;
      gap: 25px;

      .itn-forgot-password {
        text-decoration: none;
        color: #55a1d4;
        font-size: 13px;
        margin-bottom: 10px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .itn-copyright {
    position: absolute;
    bottom: 25px;
    text-decoration: none;
    color: #a8a8a8;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    .itn-copyright-icon {
      width: 11px;
      margin: 0 3px;
    }
  }
}

.itn-signin-loadingpage {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 0;
  justify-content: center;
  align-items: center;

  .itn-signin-animation {
    width: 320px;
    height: 320px;
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .itn-signin-copy {
    font-size: 0.9rem;
    font-weight: 600;
    letter-spacing: 0.05em;
    color: #a8a8a8;
  }
}

@media screen and (max-width: 520px) {
  .itn-signin-page {
    .itn-signin-window {
      width: 70%;
    }
  }
}
