.itn-menu {
  max-width: 1024px;
  margin: auto;
  margin-top: 5rem;
  margin-bottom: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.08);

  .itn-menu-button {
    border: none;
    color: #55a1d4;
    background-color: #fff;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.7rem 0;
    letter-spacing: 0.5px;
    cursor: pointer;

    &:hover {
      color: #fff;
      background-color: #2b74a5;
      transition: all 200ms ease;
    }
  }

  .itn-menu-trial {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .itn-menu-sites {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .itn-menu-button-active {
    background-color: #55a1d4;
    color: #fff;
  }
}

@include desktop {
  .itn-menu {
    width: 85%;
    .itn-menu-button {
      font-size: 0.9rem;
    }
  }
}
