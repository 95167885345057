@import "mixin";
@import "animation";

.itn-main {
  width: 100%;

  .itn-main-content {
    max-width: 1024px;
    margin: auto;
    padding: 5rem 0;

    .itn-main-header {
      .itn-main-title-section {
        .itn-main-title {
          font-size: 1.8rem;
          font-weight: 700;
          margin-top: 1rem;
        }
      }

      .itn-main-filter-section {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        column-gap: 0.8rem;
        row-gap: 0.8rem;
        margin-top: 4rem;
        margin-bottom: 3.2rem;

        .itn-main-filter-title {
          font-size: 0.9rem;
          font-weight: 600;
        }

        .itn-main-filter-button {
          border: 1.5px solid transparent;
          background-color: #e1e1e1;
          border-radius: 5px;
          color: #858585;
          padding: 0.5rem 0.8rem;
          cursor: pointer;
          transition: all 200ms ease;

          &:hover {
            border: 1.5px solid #5180ae;
          }
        }

        .active-filter {
          background-color: #5180ae;
          color: #fff;
        }

        .disabled-filter {
          background-color: #e9e9e9;
          color: #c9c9c9;
          cursor: not-allowed;

          &:hover {
            border-color: transparent;
          }
        }

        .itn-main-filter-clear {
          display: flex;
          align-items: center;
          background-color: #2f2f2f;
          color: #fff;

          .clear-icon {
            font-size: 0.9rem;
          }
          &:hover {
            border-color: transparent;
          }
        }
      }

      .itn-main-search-section {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .itn-main-result-text {
          width: 100%;
          font-size: 1.05rem;
          font-weight: 600;
          text-align: left;
        }

        .itn-main-search-section {
          place-self: end;
          display: flex;
          align-items: center;
          position: relative;

          .itn-main-search-title {
            position: absolute;
            left: 0.8rem;
            color: #2f2f2f;
          }

          .itn-main-search {
            background-color: #fff;
            border: none;
            box-shadow: 0 1px 12px rgb(0 0 0 / 8%);
            border-radius: 5px;
            width: 10rem;
            padding: 0.7rem 0.9rem;
            padding-left: 2.2rem;
            outline: none;
          }

          .itn-main-search-clear {
            position: absolute;
            background: none;
            border: none;
            font-size: 0.9rem;
            right: 0.8rem;
            color: #858585;
            cursor: pointer;
            top: 50%;
            transform: translateY(-40%);
            transition: all 200ms ease;

            &:hover {
              color: #2f2f2f;
            }
          }
        }
      }
    }

    .itn-main-studies {
      margin-top: 2.3rem;
      margin-bottom: 2rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2.2rem;

      .itn-study-not-found {
        width: 100%;
        grid-column: 1 / span 3;
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
        margin: 5rem 0;
        color: #858585;
      }

      .itn-main-study-card {
        background-color: #fff;
        padding: 2rem 2.3rem;
        box-shadow: 0 1px 12px rgb(0 0 0 / 8%);
        border-radius: 10px;
        animation: fadeIn 500ms;
        transition: all 300ms ease;

        &:hover {
          transform: translateY(-4px);
        }

        .itn-main-study-title {
          position: absolute;
          visibility: hidden;
        }

        .itn-main-study-header {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1.5rem;
          margin-bottom: 1em;

          .itn-main-study-logo {
            height: 2.5rem;
            mix-blend-mode: darken;
          }

          .itn-main-study-description {
            text-align: center;
            font-size: 0.9rem;
            height: 3rem;
          }
        }

        .itn-main-study-line {
          margin-top: 0.7rem;
          margin-bottom: 1.5rem;
        }

        .itn-main-study-link-line {
          margin-top: 1.15rem;
          margin-bottom: 1rem;
        }

        .itn-main-study-status {
          display: flex;
          justify-content: space-between;
          margin: 0.4rem 0;
          font-size: 0.9rem;

          .itn-main-study-status-title {
            font-weight: 600;
          }

          .itn-main-study-status-content {
            text-transform: capitalize;
            text-align: right;
            display: flex;
            cursor: default;

            .asterisk {
              font-size: 12px;
              align-self: flex-start;
              color: #c22a20;
            }
          }

          .itn-main-study-status-open,
          .itn-main-study-status-online {
            color: #4db60e;
          }

          .itn-main-study-status-closed,
          .itn-main-study-status-offline {
            color: #c22a20;
          }

          .itn-main-study-status-development {
            color: #55a1d4;
          }

          .itn-main-study-status-link {
            color: #2f2f2f;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
              color: #55a1d4;
            }
          }

          .itn-main-study-status-study-link {
            text-transform: lowercase;
          }

          .itn-main-study-develop-link {
            text-transform: uppercase;
          }

          .itn-main-study-date {
            font-size: 13px;
          }

          .itn-main-study-date-red {
            color: #c22a20;
          }
        }

        .itn-main-study-links {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin: 0.4rem 0;

          .itn-main-study-status-link {
            color: #2f2f2f;
            text-decoration: underline;
            cursor: pointer;
            font-size: 0.9rem;

            &:hover {
              color: #55a1d4;
            }
          }
        }
      }
    }

    .side-note {
      font-size: 0.75em;
      font-style: italic;
      color: #858585;

      .asterisk {
        color: #c22a20;
      }
    }

    .itn-main-sites {
      margin-top: 2.3rem;
      margin-bottom: 2rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
      width: 100%;

      .itn-main-site-card {
        background-color: #fff;
        padding: 1.7rem 2.5rem;
        box-shadow: 0 1px 12px rgb(0 0 0 / 8%);
        border-radius: 10px;
        animation: fadeIn 500ms;
        transition: all 300ms ease;

        &:hover {
          transform: translateY(-4px);
        }

        .itn-main-site-title {
          position: absolute;
          visibility: hidden;
        }

        .itn-main-site-header {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1.5rem;

          .itn-main-site-logo {
            width: 12rem;
            mix-blend-mode: darken;
          }
        }

        .itn-main-site-line {
          margin: 1.5rem 0;
        }

        .itn-main-site-status {
          display: flex;
          justify-content: space-between;
          margin: 0.3rem 0;
          font-size: 0.9rem;

          .itn-main-site-status-title {
            font-weight: 600;
          }

          .itn-main-site-status-content {
            text-transform: capitalize;
          }

          .itn-main-study-status-online {
            color: #4db60e;
          }

          .itn-main-study-status-offline {
            color: #c22a20;
          }

          .itn-main-site-status-link {
            color: #2f2f2f;
            text-decoration: underline;
            word-break: break-all;
            text-transform: lowercase;

            &:hover {
              color: #55a1d4;
            }
          }

          .itn-main-site-develop-link {
            text-transform: uppercase;
          }

          .itn-main-site-date-red {
            color: #c22a20;
          }
        }
      }
    }
  }
}

@include desktop {
  .itn-main {
    .itn-main-content {
      width: 85%;

      .itn-main-studies {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@include mobile {
  .itn-main {
    .itn-main-content {
      .itn-main-header {
        .itn-main-title-section {
          .itn-main-title {
            font-size: 2rem;
          }
        }

        .itn-main-filter-section {
          margin: 3rem 0;
        }
      }

      .itn-main-studies {
        grid-template-columns: 1fr;
      }

      .itn-main-sites {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
